import React, { Component } from 'react';
import { Navbar,Nav } from 'react-bootstrap';
import Survey from '../components/survey';
import News from '../components/news';
import HelpAndSupport from '../components/helpAndSupport';
import AccountOverview from '../components/accountOverview';
import MultipleAccView from '../components/multipleAccountView';
import MyBills from '../components/myBills';
import $ from 'jquery';
import { connect } from 'react-redux';
import { 
    saveAccountId,
    savePremiseAddress,
    fetchBillsList,
    fetchMultipleAddOpptyRequest
} from '../actions/dashboardActions';
import Loading from '../components/loading';
import sha256 from 'js-sha256';
import { 
    showMessage
} from '../actions/popupMessageActions';
import { Redirect } from 'react-router-dom';
import { Version } from '../components/version';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            child: '',
            accountId: '',
            viewAccountInfo: false,
            isMultipleAccount: false,
            displayLogin: false
        };
    }
    
    componentDidMount(){
        this.userInactivity()
        let prepaidAccountId = localStorage.getItem("prepaidAccountId")
        let prepaidAccountIds = localStorage.getItem("prepaidAccountIds")
        let postpaidAccountId  = localStorage.getItem("postpaidAccountId")
        let postpaidAccountIds = localStorage.getItem("postpaidAccountIds")
        if(!(prepaidAccountId === null) || !(prepaidAccountIds === null)){
            this.checkIfPrepaidAccs()
        }
        else if(!(postpaidAccountId === null) || !(postpaidAccountIds === null)){
            this.checkIfPostpaidAccs()
        }
        else{
            this.setState({
                displayLogin: true,
            })
        }
    }
    checkIfPrepaidAccs = () => {
        //session variable search
        let sessionAccountId;
        let lsAccountId  = localStorage.getItem("prepaidAccountId")
        let lsAccountIds = localStorage.getItem("prepaidAccountIds")
        if(!(lsAccountId === '' || lsAccountId === null || lsAccountId === undefined)){
            var accountId = [];
            var arrAccountId = lsAccountId.split(",")
            accountId.push([arrAccountId[0], arrAccountId[1]])
            sessionAccountId = accountId
        }
        else if(!(lsAccountIds === '' || lsAccountIds === null || lsAccountIds === undefined)){
            var storageAccIds = [], premiseAddresses = [], currPaidAmounts = [], accountIds = [];
            var arrAccountIds = lsAccountIds.split(",")
            let flag = 0
            for(var count = 0; count < arrAccountIds.length; count++){
                if(flag === 0){
                    storageAccIds.push(arrAccountIds[count])
                    flag = flag + 1
                }
                else if(flag === 1){
                    premiseAddresses.push(arrAccountIds[count])
                    flag = flag + 1
                }
                else if(flag === 2){
                    currPaidAmounts.push(arrAccountIds[count])
                    flag = 0
                }
            }
            for(var count = 0; count < storageAccIds.length; count++){
                accountIds.push([storageAccIds[count], premiseAddresses[count], currPaidAmounts[count]])
            }
            sessionAccountId = accountIds;
        }
        if(sessionAccountId === "" || sessionAccountId === null || sessionAccountId === undefined){
            this.setState({
                displayLogin: true,
            })
        }
        else{
            this.setState({
                isMultipleAccount: sessionAccountId.length > 1 ? true : false
            }, () => {
                this.props.saveAccountId(sessionAccountId[0][0])   
                .then(() => {
                    this.props.savePremiseAddress(sessionAccountId[0][1])
                    .then(() => {
                        this.setState({
                            ...this.state,
                            child: MultipleAccView
                        })
                    })
                })
            })
        }
    }
    checkIfPostpaidAccs = () => {
        //session variable search
        let sessionAccountId;
        let lsAccountId  = localStorage.getItem("postpaidAccountId")
        let lsAccountIds = localStorage.getItem("postpaidAccountIds")
        if(!(lsAccountId === '' || lsAccountId === null || lsAccountId === undefined)){
            var accountId = [];
            var arrAccountId = lsAccountId.split(",")
            accountId.push([arrAccountId[0], arrAccountId[1]])
            sessionAccountId = accountId
        }
        else if(!(lsAccountIds === '' || lsAccountIds === null || lsAccountIds === undefined)){
            var storageAccIds = [], premiseAddresses = [], currPaidAmounts = [], accountIds = [];
            var arrAccountIds = lsAccountIds.split(",")
            let flag = 0
            for(var count = 0; count < arrAccountIds.length; count++){
                if(flag === 0){
                    storageAccIds.push(arrAccountIds[count])
                    flag = flag + 1
                }
                else if(flag === 1){
                    premiseAddresses.push(arrAccountIds[count])
                    flag = flag + 1
                }
                else if(flag === 2){
                    currPaidAmounts.push(arrAccountIds[count])
                    flag = 0
                }
            }
            for(var count = 0; count < storageAccIds.length; count++){
                accountIds.push([storageAccIds[count], premiseAddresses[count], currPaidAmounts[count]])
            }
            sessionAccountId = accountIds;
        }
        if(sessionAccountId === "" || sessionAccountId === null || sessionAccountId === undefined){
            this.setState({
                displayLogin: true,
            })
        }
        else{
            this.setState({
                isMultipleAccount: sessionAccountId.length > 1 ? true : false
            }, () => {
                this.props.saveAccountId(sessionAccountId[0][0])   
                .then(() => {
                    this.props.savePremiseAddress(sessionAccountId[0][1])
                    .then(() => {
                        this.setState({
                            ...this.state,
                            child: MultipleAccView
                        })
                    })
                })
            })
        }
    }
    userInactivity() {
        var time = new Date().getTime();
        $(document.body).bind("mousemove keypress", function(e) {
            time = new Date().getTime();
        });
        const minute = 60000
        function refresh() {
            if(new Date().getTime() - time >= (minute*15)) {
                localStorage.clear();
                window.location.replace("/");
            }
            else {
                setTimeout(refresh, 10000);
            } 
        }
        setTimeout(refresh, 10000);
    }

    showAccountOverview = e => {
        e.preventDefault();
        if($('#btnCancelEditAcc').length){
            window.location.reload();
        }
        this.setState({ child: MultipleAccView, viewAccountInfo: false })
    }

    showAccountInfo = e => {
        e.preventDefault()
        this.setState({ child: '', viewAccountInfo: true }, () => {
            this.setState({ child: AccountOverview, viewAccountInfo: true })
        })
    }

    showMyBills = e => {
        e.preventDefault()
        this.setState({ child: ''}, () => {
            const accountId = this.props.dashboard.selectedAccountId
            this.props.fetchBillsList(accountId)
            .then(() => {
                this.setState({ child: MyBills, viewAccountInfo: false })
            })
            .catch((error)=>{
                this.props.showMessage(true, "Server failed! \nPlease try again!");
            })
        })
    }

    showSurvey = e => {
        e.preventDefault()
        this.setState({ child: Survey, viewAccountInfo: false })
    }

    showNews = e => {
        e.preventDefault()
        this.setState({ child: News, viewAccountInfo: false })
    }

    showHelpAndSupport = e => {
        e.preventDefault()
        this.setState({ child: HelpAndSupport, viewAccountInfo: false })
    }
    displayLogin = (e) => {
        e.preventDefault();
        localStorage.clear();
        this.setState({
            displayLogin: true,
        })
    }
    getVersion = () => {
        const url = window.location.origin;
        if(url.includes("xtendly") || url.includes("localhost")){
            return "(DEV) v" + Version.number;
        }
        return "";
    }
    render() { 
        const divStyle = {
            backgroundColor: '#00469e'
        };
        const divStyleRight = {
            backgroundColor: 'white'
        };

        if(this.state.displayLogin) {
            return <Redirect to="/" />
        }
        return (  
            <section id="dashboard" style={divStyle}>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-lg-2 p-0 row-eq-height left">
                            <div className="nav-wrap">
                                <div className="pb-3">
                                    <img className="gpa_logo" src={process.env.PUBLIC_URL + '/images/GPA-Logo.png'} alt="gpa-logo"></img>
                                </div>
                                <div className="pt-2">
                                    <a href="/#" onClick={this.showAccountOverview.bind(this)}><img className="account_logo" src={process.env.PUBLIC_URL + '/images/account-overview.png'} alt="account-logo"></img></a>
                                    <a href="/#" onClick={this.showAccountOverview.bind(this)}><p>Account Overview</p></a>
                                </div>
                                {/* <div className="pt-2">
                                    <a href="/#" onClick={this.showAccountInfo.bind(this)}><img className="account_logo" src={process.env.PUBLIC_URL + '/images/info-button.png'} alt="account-info-logo"></img></a>
                                    <a href="/#" onClick={this.showAccountInfo.bind(this)}><p>Account Information</p></a>
                                </div>
                                <div className="pt-2">
                                    <a href="/#" onClick={this.showMyBills.bind(this)}><img className="account_logo" src={process.env.PUBLIC_URL + '/images/my-bills.png'} alt="account-logo"></img></a>
                                    <a href="/#" onClick={this.showMyBills.bind(this)}><p>My Bills</p></a>
                                </div> */}
                                <hr />
                                <div className="links">
                                    <a href="/#" onClick={this.showSurvey.bind(this)}><p>Survey</p></a>
                                    {/* <a href="/#" onClick={this.showNews.bind(this)}><p>News</p></a> */}
                                    <a href="/#" onClick={this.showHelpAndSupport.bind(this)}><p>Help & Support</p></a>
                                    <p>{this.getVersion()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10 p-0 row-eq-height right" style={divStyleRight}>
                            <div className="ml-0">
                            <Navbar collapseOnSelect expand="lg" className="nav_mobile primary-back-color" variant="dark">
                                <Navbar.Brand><img className="gpa_logo" src={process.env.PUBLIC_URL + '/images/logo-with-label.png'} alt="gpa-logo"></img></Navbar.Brand>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav">
                                    <Nav className="mr-auto">
                                        <a href="/#" onClick={this.showAccountOverview.bind(this)}><p>Account Overview</p></a>
                                        {/* <a href="/#" onClick={this.showAccountInfo.bind(this)}><p>Account Information</p></a>
                                        <a href="/#" onClick={this.showMyBills.bind(this)}><p>My Bills</p></a> */}
                                        <a href="/#" onClick={this.showSurvey.bind(this)}><p>Survey</p></a>
                                        {/* <a href="/#" onClick={this.showNews.bind(this)}><p>News</p></a> */}
                                        <a href="/#" onClick={this.showHelpAndSupport.bind(this)}><p>Help & Support</p></a>
                                        <hr/>
                                        <a href="/" onClick={this.displayLogin.bind(this)}><p>Logout</p></a>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                                <div className="main">
                                    {(this.state.child !== '') ? React.createElement(this.state.child,{viewAccountInfo: this.state.viewAccountInfo},null) 
                                    : 
                                    <Loading />}
                                    {/* <Loading /> */}
                                </div>
                            </div>
                            <br />
                            <br />
                            <br />
                            <footer id="footer-main">
                                <hr />
                                <div className="text-center float-left pl-5 pt-2">
                                    <p className="m-0">All Rights Reserved © 2020 Guam Power Authority</p>
                                </div>
                                <div className="text-center float-right pr-5 pb-2">
                                <a href="https://www.guampowerauthority.com/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/guam-logo-small.png'} alt="guam-logo-small"></img></a>
                                    <a href="http://guamwaterworks.org/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/guam-waterworks.png'} alt="guam-waterworks.png"></img></a>
                                    <a href="https://www.myenergyguam.com/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/guam-me-energy.png'} alt="guam-me-energy"></img></a>
                                    <a href="http://guamccu.org/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/guam-ccu.png'} alt="guam-ccu.png"></img></a>
                                    <a href="https://www.guamsolidwasteauthority.com/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/GSWA.PNG'} alt="GSWA.png" width="42"></img></a>   
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => ({
    users:     state.users,
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    saveAccountId,
    savePremiseAddress,
    fetchBillsList,
    fetchMultipleAddOpptyRequest,
    showMessage
})(Dashboard);