import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { 
    
} from '../actions/paynowActions';
import { Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';


class PaynowStep2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isCashOnlyFlag: false,
          isCashOnlyFlagLoaded: false,
        };
    }
    
    maskString = (value) => {
        let str = value;
        let count = 0;
        let new_str = "";
        try{
            for(let i = 0; i < str.length; i++){
                if(count > 1){
                    if(str.charAt(i) != " "){
                        new_str = new_str + '*'
                        count = count + 1
                    }
                    else{
                        new_str = new_str + " "
                        count = 0;
                    }
                    
                }
                else if(count < 2){
                    if(str.charAt(i) != " "){
                        new_str = new_str + str.charAt(i)
                        count = count + 1
                    }
                    else{
                        new_str = new_str + " "
                        count = 0;
                    }
                }
                
            }
            return new_str;
        }
        catch(error){
            return "";
        }
    }

    /////////////////////////////////////
    // Handle Cash Only Flag
    /////////////////////////////////////
    checkCashOnlyFlag = (newUrl) => {
        const {details} = this.props.paynow.validateZipCodeRes.result;
        const accountId = details.personAddressOverride.accountId;
        console.log("This is the account ids", accountId);
        return axios
            .post(
                newUrl,
                {
                    accountId: accountId
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            .then(response => {
                console.log("Fetched data for cash-only-flag", response.data?.result?.cashOnlyFlag);
                // Update Cash Only Flag
                if (response.data?.result?.cashOnlyFlag === "Y") {
                    this.setState({
                        isCashOnlyFlag: true,
                        isCashOnlyFlagLoaded: true
                    });
                } else {
                    this.setState({
                    //
                    });
                }
            })
            .catch(err => {
                console.log("Failed to fetch api cash-only-flag", err);
                this.setState({
                    isCashOnlyFlagLoaded: true,
                    // isCashOnlyFlag: true
                })
            })
    }

    componentWillMount() {
        // Validate account IDs if Cash Only
        const newUrl = process.env.REACT_APP_DASHBOARD_API_URL;
        const {details} = this.props.paynow?.validateZipCodeRes?.result;

        if (!details || this.state.isCashOnlyFlagLoaded) {
          //
        } else if (newUrl.endsWith("/")) {
            this.checkCashOnlyFlag(newUrl + "api/v2/user-latest-bill");
        } else {
            this.checkCashOnlyFlag(newUrl + "/api/v2/user-latest-bill");
        }
    }

    render() { 
        const {details, source} = this.props.paynow.validateZipCodeRes.result;
        let fullName  = details.EntityInfo;

        const finalFullName = this.maskString(fullName);

        const address1 = source != "ACOV" ? details.Address : details.personAddressOverride.address1 + ', ' + details.personAddressOverride.city + ', '+ details.personAddressOverride.state + ', '+ details.personAddressOverride.postal + ', '+ details.personAddressOverride.country;

        const finalAdd1 = this.maskString(address1);
        
        return (  
            <React.Fragment>
                <h4>Is This You?</h4>
                <br />
                {
                    this.state.isCashOnlyFlag
                    ? <div className="">
                        <h6 style={{color: "red"}}>NOTICE: Your account(s) has been flagged for Cash Only payments. Please contact the GPA Customer Service Division for further details.</h6>
                        </div>
                    : <div></div>
                }
                <Form autocomplete="off">
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label className="font-bold">Customer Name</Form.Label>
                            <Form.Control
                            autocomplete="off"
                                required
                                type="text"
                                name="cname"
                                value={finalFullName}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your Customer Name.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label className="font-bold">Last Name</Form.Label>
                            <Form.Control
                            autocomplete="off"
                                required
                                type="text"
                                name="lname"
                                value={finalLastName}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your Last Name.
                            </Form.Control.Feedback>
                        </Form.Group> */}
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label className="font-bold">Address</Form.Label>
                            <Form.Control
                            autocomplete="off"
                                required
                                type="text"
                                name="address"
                                value={finalAdd1}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your Address 1.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label className="font-bold">Address 2</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="address2"
                                value={finalAdd2}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your Address 2.
                            </Form.Control.Feedback>
                        </Form.Group> */}
                    </Form.Row>
                </Form>
                <br />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    paynow: state.paynow
});

export default connect(mapStateToProps, { })(PaynowStep2);