import axios from "axios";
import {
  SAVE_ACCOUNT_ID,
  SAVE_PREMISE_ADDRESS,
  FETCH_USER_DETAILS,
  FETCH_USER_OLD_DETAILS,
  FETCH_EDIT_SECURITY_QUESTIONS,
  FETCH_COUNTRIES,
  FETCH_LATEST_BILL,
  FETCH_CONSUMPTION_DETAILS,
  FETCH_BILL_LIST,
  FETCH_SURVEY_LIST,
  SAVE_VIEW_BILL_DATA,
  SAVE_ORDER_DATA,
  FETCH_PAYMENT_HISTORY_LIST,
} from "./types";
import curlirize from "axios-curlirize";
import * as moment from "moment";

const dashboardBaseURL = process.env.REACT_APP_DASHBOARD_API_URL;
const loginSignupBaseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;
const paynowBaseURL = process.env.REACT_APP_PAYNOW_API_URL;

function savePaymentToMarketingDB(
  postData,
  payEezyResult,
  finalTransDate,
  accountId,
  overallId,
  amountToBePaid
) {
  return axios.post(
    paynowBaseURL + "/api/v1/save-payment",
    {
      overall_id: overallId,
      payment_date: finalTransDate,
      account_number: accountId,
      amount: amountToBePaid,
      status: "PAID",
      paid_by: postData.username,
      receipt_num: payEezyResult.data.Reference_No,
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
export const searchString = (str, result) => (dispatch) => {
  let value = doTheSearch(str, result);
  return value;
};
function saveOverallPayment(postData, payEezyResult, finalTransDate) {
  return axios.post(
    paynowBaseURL + "/api/v1/save-overall-payment",
    {
      username: postData.username,
      payment_date: finalTransDate,
      receipt_number: payEezyResult.data.Reference_No,
      transaction_tag: payEezyResult.data.Transaction_Tag,
      pay_eezy_seq_num: payEezyResult.data.SequenceNo,
      amount: postData.subtotal,
      process_message: payEezyResult.data.EXact_Message,
      bank_response: payEezyResult.data.Bank_Message,
      mode: postData.mode === "admin" ? "Phoned-In" : "Online",
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
function sendPaymentStatEmail(
  postData,
  accountIdsList,
  payEezyResult,
  transDate
) {
  return axios.post(
    paynowBaseURL + "/api/v1/confirm-email",
    {
      accountNum: accountIdsList,
      email: postData.cardDetails.confirmationEmail,
      receiptNum: payEezyResult.data.Reference_No,
      cardholderName: payEezyResult.data.CardHoldersName,
      confirmationNum: payEezyResult.data.Transaction_Tag,
      transacDate: transDate,
      creditNum: payEezyResult.data.Card_Number,
      authorizationNum: payEezyResult.data.Authorization_Num,
      amount: postData.subtotal,
      userFullName: postData.userFullName,
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
function doTheSearch(str, result) {
  const ctr = JSON.stringify(result.exact_ctr);
  if(process.env.REACT_APP_USE_OLD_PAYEEZI === 'true'){
    const ctrEscaped = ctr
      .replace(/\\n/g, "$\\n")
      .replace(/\\'/g, "$\\'")
      .replace(/\\"/g, '$\\"')
      .replace(/\\&/g, "$\\&")
      .replace(/\\r/g, "$\\r")
      .replace(/\\t/g, "$\\t")
      .replace(/\\b/g, "$\\b")
      .replace(/\\f/g, "$\\f");
    const index = ctrEscaped.search(str); //get the index of searched string
    let flagStart = false;
    let value = "";
    for (let count = index; count < ctrEscaped.length; count++) {
      if (!flagStart) {
        if (ctrEscaped.charAt(count) === ":") {
          flagStart = true;
        }
      } else {
        if (ctrEscaped.charAt(count) != "$") {
          value += ctrEscaped.charAt(count);
        } else {
          break;
        }
      }
    }
    return value;
  }else{
    const inputString = ctr; 
    const patterns = [
        /TRANS\. REF\. (\d+)/,
        /PurchaseACCT: ([^\s]+)/,
        /CARDHOLDER NAME : ([^\s]+)/,
        /REFERENCE #     : ([^\s]+)/,
        /AUTHOR\. #       : ([^\s]+)/,
        /CARD NUMBER\s+: ([^\s]+)/,
    ];
    
    const patternValues = [];
    
    patterns.forEach(pattern => {
        const matches = inputString.match(pattern);
        patternValues.push(matches && matches[1] ? matches[1] : 'Not found');
    });
    
    // Assign patternValues to variables
    const acct = patternValues[1];
    const cardHolder = patternValues[2].replace('CARD', '');
    const transRef = patternValues[0];
    const sequence = patternValues[3].replace('AUTHOR.', '');
    const authNum = patternValues[4].replace('TRANS.', '');
    const cardNumber = patternValues[5].replace('DATE/TIME', '');

    if(str === "TRANS. REF."){
      return transRef;
    }else if(str === "AUTHOR. #"){
      return authNum;
    }else if(str === "REFERENCE #"){
      return sequence;
    }
  }
  return "N/A";
}
function saveToPayEezy(postData) {
  return axios.post(
    paynowBaseURL + "/api/v1/payeezy",
    {
      amount: postData.subtotal,
      card_num: postData.cardDetails.cardNumber,
      exp_date: postData.cardDetails.validExpDate,
      card_holder: postData.cardDetails.cardHolderName,
      CAVV: postData.cardDetails.cvv,
      custome_ref: postData.mode === "admin" ? "PHONEDIN" : "ONLINE",
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
function fetchLatestPayment(accountId) {
  return axios.post(
    paynowBaseURL + "/api/v1/validate",
    {
      accountId: accountId,
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
function savePaymentToCCB(accountId, amount) {
  console.log(
    "savePaymentToCCBsavePaymentToCCBsavePaymentToCCBsavePaymentToCCB"
  );
  console.log(accountId);
  console.log(amount);
  return axios.post(
    paynowBaseURL + "/api/v1/make-payment",
    {
      accountId: accountId,
      amount: amount,
      method: "PG001",
      cardCharge: "0.00",
      paymentSource: "CCV",
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
export const fetchPaymentHistory = (accountId) => (dispatch) => {
  console.log("accountIdaccountIdaccountIdaccountId", accountId);
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/payment-history",
        {
          accountId: accountId,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        const billList = response.data.result;
        const billListTableData = [];
        try {
          for (var count = 0; count < billList.length; count++) {
            try {
              billListTableData.push({
                payment_date: billList[count].ArrearsDate,
                account_number: billList[count].Parent,
                billTotalAmount: billList[count].TotalAmount,
                transType: billList[count].FinancialTransactionType,
              });
            } catch (ex) {
              break;
            }
          }
        } catch (ex) {}
        dispatch({
          type: FETCH_PAYMENT_HISTORY_LIST,
          payload: billListTableData,
        });
        resolve(billListTableData);
      })
      .catch((error) => {
        console.log(error);
      });
  });
};
export const saveAccountId = (accountId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: SAVE_ACCOUNT_ID,
      payload: accountId,
    });
    resolve(accountId);
  });
};
export const validateVisaPayment = (accountId) => (dispatch) => {
  let arrLatestPayment = [];
  for (let count = 0; count < 1; count++) {
    arrLatestPayment.push(fetchLatestPayment(accountId));
  }
  return new Promise((resolve, reject) => {
    axios
      .all(arrLatestPayment)
      .then((response) => {
        const resLatestPayment = response[0];
        let arrResults = [];
        console.log(
          "wawawawawawdadwdwadawdawdwadadwdwawdadwad",
          resLatestPayment
        );
        // for(let count = 0; count < resLatestPayment.length; count++){
        //     resLatestPayment[count].then(paymentResult => {
        //         const paymentResponse = paymentResult.data.result.data
        //         arrResults.push(
        //             {
        //                 alreadyPaid: paymentResponse.PaymentAmount,
        //             }
        //         )
        //     })
        // }
        resolve(resLatestPayment);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
function payToPayeezy(item, response) {
  axios.post(
    "https://demo.globalgatewaye4.firstdata.com/payment",

    {
      x_login: response.login,
      x_fp_sequence: response.fp_sequence,
      x_fp_timestamp: response.fp_timestamp,
      x_amount: response.amount,
      x_fp_hash: response.fp_hash,
      x_invoice_num: response.fp_sequence,
      x_po_num: item.fullName,
      x_show_form: "PAYMENT_FORM",
      x_relay_response: "TRUE",
      Checkout: "Pay Now",
    }
  );
}

export const generateFpHash = (item) => (dispatch) => {
  console.log('generatteFpHash', item)
  const Xlogin =
    process.env.REACT_APP_ENVIRONEMENT === "development"
      ? "HCO-GUAM-663"
      : `${
          item.className === "RESID"
            ? "WSP-GPA-O-IzkEWADPpw"
            : "WSP-GPA-O-g1s4eADPqQ"
        }`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        paynowBaseURL + "/api/v1/get-fphash",
        {
          accountId: item.accID,
          login: Xlogin,
          type: item.className === "RESID" ? "RESID" : "COMM ",
          amount: item.amountToBePaid,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        console.log("generateFpHash", JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  
};

export const savePaymentData = (postData) => (dispatch) => {
  let arrSavePaymentToCCBRequests = [],
    arrSaveToMarketingDBRequests = [],
    accountIdsList = "";
  const accountSummary = postData.accountSummary;
  for (let count = 0; count < accountSummary.length; count++) {
    if (
      accountSummary[count].checked &&
      accountSummary[count].amountToBePaid > 0
    ) {
      accountIdsList += accountSummary[count].accID + ", ";
      arrSavePaymentToCCBRequests.push(
        savePaymentToCCB(
          accountSummary[count].accID,
          accountSummary[count].amountToBePaid
        )
      );
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .all([saveToPayEezy(postData), arrSavePaymentToCCBRequests])
      .then(
        axios.spread((saveToPayEezyResponse, savePaymentToCCBResponse) => {
          const payEezyResult = saveToPayEezyResponse.data.result;
          const transDate = doTheSearch("DATE/TIME", payEezyResult);
          const arrTransDate = transDate.split(" ");
          let finalTransDate =
            arrTransDate[1] + "-" + arrTransDate[2] + "-" + arrTransDate[3];
          let d = new Date(finalTransDate);
          finalTransDate =
            "20" +
            arrTransDate[3] +
            "/" +
            (d.getMonth() + 1) +
            "/" +
            arrTransDate[1];
          axios
            .all([
              sendPaymentStatEmail(
                postData,
                accountIdsList,
                payEezyResult,
                transDate
              ),
              saveOverallPayment(postData, payEezyResult, finalTransDate),
            ])
            .then(
              axios.spread(
                (sendPaymentStatEmailResponse, saveOverallPaymentResponse) => {
                  for (let count = 0; count < accountSummary.length; count++) {
                    if (
                      accountSummary[count].checked &&
                      accountSummary[count].amountToBePaid > 0
                    ) {
                      arrSaveToMarketingDBRequests.push(
                        savePaymentToMarketingDB(
                          postData,
                          payEezyResult,
                          finalTransDate,
                          accountSummary[count].accID,
                          saveOverallPaymentResponse.data.overallId,
                          accountSummary[count].amountToBePaid
                        )
                      );
                    }
                  }
                  axios.all([arrSaveToMarketingDBRequests]).then(
                    axios.spread((saveToMarketingDBRequestsResponse) => {
                      resolve(saveToPayEezyResponse.data.result.data);
                    })
                  );
                }
              )
            );
        })
      )
      .catch((error) => {
        reject(error);
      });
  });
};
export const saveOrderData = (postData) => (dispatch) => {
  const orderData = {
    accountSummary: postData.accountSummary,
    subTotal: postData.subTotal,
  };
  dispatch({
    type: SAVE_ORDER_DATA,
    payload: orderData,
  });
};
export const savePremiseAddress = (premiseAddress) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: SAVE_PREMISE_ADDRESS,
      payload: premiseAddress,
    });
    resolve(premiseAddress);
  });
};

export const submitHelpAndSupport = (postData) => (dispatch) => {
  let personId;
  personId = localStorage.getItem("personId");

  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/help-and-support",
        {
          cis_division: "GPA",
          accountId: postData.accountId,
          personId: personId,
          emailAddress: postData.emailAddress,
          fullName: postData.fullName,
          contactType: postData.contactType,
          message: postData.message,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        resolve(response.data.result.status);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
function buildPrepaidAccountSummary(accountId, count, billResponse) {
  return {
    accountType: "prepaid",
    checked: true,
    validAmountToBePaid: true,
    accID: accountId[count][0],
    serviceLocation: accountId[count][1],
    amountToBePaid: 0,
    debtRecovery:
      billResponse.result.unpaidBalance != undefined
        ? billResponse.result.unpaidBalance
        : billResponse.arrears.details.CurrentBalance,
    electricBalance:
      billResponse.result.prepayBalance != undefined
        ? billResponse.result.prepayBalance
        : billResponse.arrears.details.PayoffBalance,
    fullName: billResponse.arrears.details.Name,
    arrears: billResponse.arrears,
    prepaySAId: billResponse.result.prepaySAId,
    cashOnlyFlag: billResponse.cashOnlyFlag
  };
}
function formatDate(date) {
  return moment(date).format("MM/YYYY");
}
function buildPostpaidAccountSummary(accountId, count, billResponse) {
  let billDate = new Date(billResponse.date.billDate);
  let finalBillDate = moment(billDate).format("MM/DD/YYYY");
  let dueDate = new Date(billResponse.date.dueDate);
  let finaldueDate = moment(dueDate).format("MM/DD/YYYY");
  // const paymentResponse = paymentResult.data.result.data
  let isDueDate = "",
    arrearsTotal = 0;
  const arrears = billResponse.arrears.arrears;
  for (let count = 0; count < arrears.length; count++) {
    if (
      !arrears[count].Label.includes("new charges") &&
      arrears[count].Label != ""
    ) {
      arrearsTotal = arrearsTotal + parseFloat(arrears[count].ArrearsAmount);
    }
  }
  let isDueDateRed = false;
  if (arrearsTotal === 0) {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let dueDate1 = new Date(dueDate);
    dueDate1.setHours(0, 0, 0, 0);
    if (dueDate <= today) {
      isDueDateRed = true;
    } else if (dueDate > today) {
      isDueDateRed = false;
    }
  } else {
    finaldueDate = "Due Now";
    isDueDateRed = true;
  }
  return {
    accountType: "postpaid",
    checked: false,
    validAmountToBePaid: true,
    accID: accountId[count][0],
    serviceLocation: accountId[count][1],
    billDate: finalBillDate,
    amount: billResponse.arrears.details.CurrentBalance,
    isDueDateRed: isDueDateRed,
    dueDate: finaldueDate,
    amountToBePaid:
      parseFloat(billResponse.arrears.details.CurrentBalance) > 0
        ? parseFloat(billResponse.arrears.details.CurrentBalance)
        : 0.0,
    className: accountId[count][2],
    alreadyPaid: 0,
    fullName: billResponse.arrears.details.Name,
    arrears: billResponse.arrears,
    arrearsTotal: arrearsTotal,
    cashOnlyFlag: billResponse.cashOnlyFlag
  };
}
export const fetchMultipleLatestBill =
  (accountId, accountType) => (dispatch) => {
    console.log(accountType);
    let arrLatestBillRequests = [];
    for (let count = 0; count < accountId.length; count++) {
      if (accountType === "prepaid") {
        arrLatestBillRequests.push(fetchPrepaidLatestBill(accountId[count][0]));
      } else {
        arrLatestBillRequests.push(fetchLatestBill(accountId[count][0]));
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .all([arrLatestBillRequests])
        .then((response) => {
          const resLatestBillRequests = response[0];
          let accountSummary = [];
          for (let count = 0; count < resLatestBillRequests.length; count++) {
            resLatestBillRequests[count].then((billResult) => {
              if (accountType === "prepaid") {
                const billResponse = billResult.data;
                accountSummary.push(
                  buildPrepaidAccountSummary(accountId, count, billResponse)
                );
              } else {
                const billResponse = billResult.data.result;
                accountSummary.push(
                  buildPostpaidAccountSummary(accountId, count, billResponse)
                );
              }
            });
          }
          const orderData = {
            accountSummary: accountSummary,
            subTotal: 0,
          };
          return orderData;
        })
        .then((orderData) => {
          dispatch({
            type: SAVE_ORDER_DATA,
            payload: orderData,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
export const fetchMultipleAddOpptyRequest =
  (accountId, personId, accountType, prepaySAId, chartPeriod) => (dispatch) => {
    dispatch({
      type: FETCH_CONSUMPTION_DETAILS,
      payload: {},
    });
    return new Promise((resolve, reject) => {
      axios
        .all([
          fetchUserDetails(personId),
          fetchMonthlyBillConsumption(
            accountId,
            accountType,
            prepaySAId,
            chartPeriod
          ),
        ])
        .then(
          axios.spread(
            (fetchUserDetailsResponse, fetchMonthlyBillConsumptionResponse) => {
              //for fetchUserDetailsResponse
              var userDetails = {};
              userDetails.fullName =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.Name;
              userDetails.emailAddress =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.EmailID;
              let contactNumbers =
                fetchUserDetailsResponse.data.result.otherDetails
                  .contactNumbers;
              for (var count = 0; count < contactNumbers.length; count++) {
                if (
                  contactNumbers[count].PhoneType === "MOBILE" &&
                  (userDetails.mobilePhone === undefined ||
                    userDetails.mobilePhone === "")
                ) {
                  userDetails.mobilePhone = contactNumbers[count].PhoneNumber;
                  userDetails.mobilePhoneSeq = contactNumbers[count].Sequence;
                } else if (
                  contactNumbers[count].PhoneType === "HOME-PHONE" &&
                  (userDetails.homePhone === undefined ||
                    userDetails.homePhone === "")
                ) {
                  userDetails.homePhone = contactNumbers[count].PhoneNumber;
                  userDetails.homePhoneSeq = contactNumbers[count].Sequence;
                } else if (
                  contactNumbers[count].PhoneType === "BUSN-PHONE" &&
                  (userDetails.workPhone === undefined ||
                    userDetails.workPhone === "")
                ) {
                  userDetails.workPhone = contactNumbers[count].PhoneNumber;
                  userDetails.workPhoneSeq = contactNumbers[count].Sequence;
                }
              }
              userDetails.addressLine1 =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.Address;
              userDetails.addressLine2 =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.Address2;
              userDetails.city =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.City;
              userDetails.state =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.StateDescription;
              userDetails.stateInitials =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.State;
              userDetails.postal =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.Postal;
              userDetails.country =
                fetchUserDetailsResponse.data.result.otherDetails.customerDetails.Description;
              dispatch({
                type: FETCH_USER_DETAILS,
                payload: userDetails,
              });
              //for fetchLatestBillResponse
              // var billDate      = new Date(fetchLatestBillResponse.data.result.date.billDate);
              // var finalBillDate = formatDate(billDate);
              // var dueDate       = new Date(fetchLatestBillResponse.data.result.date.dueDate);
              // var finalDueDate  = formatDate(dueDate);
              // var latestBill = {
              //     billDate:    finalBillDate,
              //     totalAmount: fetchLatestBillResponse.data.result.amount,
              //     dueDate:     finalDueDate,
              // }
              // dispatch({
              //     type:    FETCH_LATEST_BILL,
              //     payload: latestBill
              // })

              if (accountType === "prepaid" && prepaySAId != "") {
                const status =
                  fetchMonthlyBillConsumptionResponse.data.response.energyCharts
                    .length === 0
                    ? "False"
                    : "True";
                if (status === "True") {
                  const data =
                    fetchMonthlyBillConsumptionResponse.data.response
                      .energyCharts;
                  let consumptionDetails = {};
                  let months = [];
                  let amounts = [];
                  let totalKwhs = [];
                  for (var count = 0; count < data.length; count++) {
                    var startDate = data[count].creationDateTime.slice(5, 10);
                    var endDate = "";
                    var amount = data[count].amount;
                    var totalKwh = Math.round(data[count].usage);
                    months.push([startDate, " ", endDate]);
                    amounts.push(amount > 0 ? amount : 0);
                    totalKwhs.push(totalKwh > 0 ? totalKwh : 0);
                  }
                  consumptionDetails.months = months;
                  consumptionDetails.amounts = amounts;
                  consumptionDetails.totalKwhs = totalKwhs;
                  consumptionDetails.moreDetails =
                    fetchMonthlyBillConsumptionResponse.data.response.details;
                  dispatch({
                    type: FETCH_CONSUMPTION_DETAILS,
                    payload: consumptionDetails,
                  });
                  const results = {
                    isHaveConsumptionChart: status,
                    dataFetched: true,
                  };
                  resolve(results);
                } else {
                  const results = {
                    isHaveConsumptionChart: status,
                    dataFetched: true,
                  };
                  resolve(results);
                }
              } else {
                const status =
                  fetchMonthlyBillConsumptionResponse.data.result.status;
                if (status === "True") {
                  const data =
                    fetchMonthlyBillConsumptionResponse.data.result.data;
                  let consumptionDetails = {};
                  let months = [];
                  let amounts = [];
                  let totalKwhs = [];
                  for (var count = 0; count < data.length; count++) {
                    var startDate = data[count][0].startReadDate.slice(5, 10);
                    var endDate = data[count][0].endReadDate.slice(5, 10);
                    var amount = data[count][1].billAmount;
                    var totalKwh = Math.round(data[count][0].measuredQuantity);
                    months.push([startDate, " - ", endDate]);
                    amounts.push(amount > 0 ? amount : 0);
                    totalKwhs.push(totalKwh > 0 ? totalKwh : 0);
                  }
                  consumptionDetails.months = months;
                  consumptionDetails.amounts = amounts;
                  consumptionDetails.totalKwhs = totalKwhs;
                  dispatch({
                    type: FETCH_CONSUMPTION_DETAILS,
                    payload: consumptionDetails,
                  });
                  const results = {
                    isHaveConsumptionChart: status,
                    dataFetched: true,
                  };
                  resolve(results);
                } else {
                  const results = {
                    isHaveConsumptionChart: status,
                    dataFetched: true,
                  };
                  resolve(results);
                }
              }
            }
          )
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
export const getSequQuestions = () => (dispatch) => {
  return axios
    .get(loginSignupBaseURL + "/api/v1/get-security-questions", {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      dispatch({
        type: FETCH_EDIT_SECURITY_QUESTIONS,
        payload: response.data.result,
      });
    });
};

export const getCountry = () => (dispatch) => {
  return axios
    .get(dashboardBaseURL + "/api/v1/get-country", {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      dispatch({
        type: FETCH_COUNTRIES,
        payload: response.data.result,
      });
    });
};

export const fetchBillsList = (accountId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/my-bills-list",
        {
          accountId: accountId,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        const billList = response.data.result;
        const billListTableData = [];
        for (var count = 0; count < billList.length; count++) {
          try {
            var billDate = new Date(billList[count].ArrearsDate);
            var finalBillDate = formatDate(billDate);
            billListTableData.push({
              billID: billList[count].Parent,
              billDate: finalBillDate,
              totalAmount: billList[count].TotalAmount,
              arrearsDate: billList[count].ArrearsDate,
            });
          } catch (ex) {
            break;
          }
        }
        dispatch({
          type: FETCH_BILL_LIST,
          payload: billListTableData,
        });
        resolve(billListTableData);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

function fetchMonthlyBillConsumption(
  accountId,
  accountType,
  prepaySAId,
  chartPeriod
) {
  if (accountType === "prepaid") {
    return axios.post(
      dashboardBaseURL + "/api/v1/user-consumption-chart-prepaid",
      {
        accountId: accountId,
        prepaySAId: prepaySAId,
        energyChartPeriod: chartPeriod,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  } else {
    return axios.post(
      dashboardBaseURL + "/api/v1/user-consumption-chart",
      {
        accountId: accountId,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }
}

function fetchLatestBill(accountId) {
  return axios.post(
    dashboardBaseURL + "/api/v2/user-latest-bill",
    {
      accountId: accountId,
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

function fetchPrepaidLatestBill(accountId) {
  return axios.post(
    dashboardBaseURL + "/api/v1/prepaid-details",
    {
      accountId: accountId,
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

function fetchUserDetails(personId) {
  return axios.post(
    dashboardBaseURL + "/api/v1/user-details",
    {
      personId: personId,
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

export const updateUserPassword = (postData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/update-user-password",
        {
          personId: postData.personId,
          oldPassword: postData.oldPassword,
          password: postData.password,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUserDetails = (postData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/update-user-details",
        {
          personId: postData.personId,
          accountId: postData.accountId,
          emailAddress: postData.emailAddress,
          address1: postData.address1,
          address2: postData.address2,
          city: postData.city,
          postal: postData.postal,
          state: postData.stateInitials,
          stateDesc: postData.stateDesc,
          country: "USA",
          home: postData.home,
          mobile: postData.mobile,
          work: postData.work,
          oldDateEmail: postData.oldDateEmail,
          oldDateQuestion: postData.oldDateQuestion,
          oldDateAnswer: postData.oldDateAnswer,
          CharacteristicValue: postData.characteristicValue,
          Answer: postData.answer,
          sequenceHome: postData.homeSeq,
          sequenceMobile: postData.mobileSeq,
          sequenceWork: postData.workSeq,
          isDeletedHome: postData.isDeletedHome,
          isDeletedMobile: postData.isDeletedMobile,
          isDeletedWork: postData.isDeletedWork,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchOldUserDetails = (personID) => (dispatch) => {
  return axios
    .post(
      dashboardBaseURL + "/api/v1/user-details-edit-account",
      {
        personID: personID,
        division: "GPA",
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then((response) => {
      const oldData = response.data.result.otherDetails;
      var userOldDetails = {};
      // userOldDetails.billAddressSource    = response.data.result.details.billAddressSource
      for (var count = 0; count < oldData.length; count++) {
        if (oldData[count].CharacteristicType === "CMSCAN") {
          userOldDetails.oldDateAnswer = oldData[count].EffectiveDate;
          userOldDetails.oldAnswerSecuQues =
            oldData[count].AdhocCharacteristicValue;
        } else if (oldData[count].CharacteristicType === "CMSCQUES") {
          userOldDetails.oldDateSecuQuestion = oldData[count].EffectiveDate;
          userOldDetails.oldCharValueSecuQues =
            oldData[count].CharacteristicValue;
        } else if (oldData[count].CharacteristicType === "CMOLDEML") {
          userOldDetails.oldDateEmailAdd = oldData[count].EffectiveDate;
        }
      }
      dispatch({
        type: FETCH_USER_OLD_DETAILS,
        payload: userOldDetails,
      });
    });
};

export const getListSurvey = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(dashboardBaseURL + "/api/v1/get-list-survey", {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        console.log("getListSurvey response");
        console.log(response.data.result.surveyList);
        dispatch({
          type: FETCH_SURVEY_LIST,
          payload: response.data.result.surveyList,
        });
        resolve(response.data.result.surveyList);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const submitSurvey = (postData) => (dispatch) => {
  let fullName = postData.userInfo.fullName;
  if (!fullName.includes(",")) {
    fullName = fullName.split(" ")[0] + ", " + fullName.split(" ")[1];
  }
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api-v1/submit-survey",
        {
          personId: postData.userInfo.personId,
          username: postData.userInfo.username,
          fullName: fullName,
          surveyAns1: postData.surveyAnswers.answer1,
          surveyAns2: postData.surveyAnswers.answer2,
          surveyAns3: postData.surveyAnswers.answer3,
          surveyAns4: postData.surveyAnswers.answer4,
          surveyAns5: postData.surveyAnswers.answer5,
          surveyAns6: postData.surveyAnswers.answer6,
          surveyAns7: postData.surveyAnswers.answer7,
          surveyAns8: postData.surveyAnswers.answer8,
          surveyAns9: postData.surveyAnswers.answer9,
          surveyAns10: postData.surveyAnswers.answer10,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        resolve(response.data.result.status);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getViewBillData = (billID) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/view-bill",
        {
          billId: billID,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        dispatch({
          type: SAVE_VIEW_BILL_DATA,
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
