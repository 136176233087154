import { 
    SHOW_MESSAGE, 
    HIDE_MESSAGE,
    SHOW_CUSTOMMESSAGE, 
    HIDE_CUSTOMMESSAGE,
} from '../actions/types';

const initialState = {
    modalConfig: {
        show: false,
        isError: false,
        message: "",
    },
    customModalConfig: {
        showCustom: false,
        isError: false,
        message: "",
    },
}

export default function(state = initialState, action){
    switch(action.type){
        case SHOW_MESSAGE:
            return {
                ...state,
                modalConfig: {
                    ...state.modalConfig,
                    show: true,
                    isError: action.payload.isError,
                    message: action.payload.message
                }
            }
        case HIDE_MESSAGE:
            return{
                ...state,
                modalConfig: {
                    ...state.modalConfig,
                    show: false,
                    isError: action.payload.isError,
                    message: action.payload.message
                }
            }
        case SHOW_CUSTOMMESSAGE:
            return {
                ...state,
                customModalConfig: {
                    ...state.modalConfig,
                    showCustom: true,
                    isError: action.payload.isError,
                    message: action.payload.message
                }
            }
        case HIDE_CUSTOMMESSAGE:
            return{
                ...state,
                customModalConfig: {
                    ...state.modalConfig,
                    showCustom: false,
                    isError: action.payload.isError,
                    message: action.payload.message
                }
            }
        default:
            return state;
    }
}