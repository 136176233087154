import React, { Component } from 'react';
import {connect} from 'react-redux'
import { Modal, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import Loading from './loading';
import { 
    showMessage, showCustomMessage, hideCustomMessage
} from '../actions/popupMessageActions';

const baseURL = process.env.REACT_APP_DASHBOARD_API_URL;

class ModalNotificationSettings extends Component {
    state = { 
        show: false,
        switchedOn: false,
        isProcessing: false,
        isLoading: true,
        updateResult: "",
        showResult: false,
        errorMessage: "",
    }
    componentDidMount() {
        
    }
    getCurrentSetting = () => {
        axios.post(baseURL+'/generate-token',
        {
            accountId: this.props.accId,
            division: 'GPA'
        },
        {
            headers: {
            'Content-Type': 'application/json',
            },
        })
        .then(response => {
            const result = response.data.result;
            const routeType = result.details.billRouteType
            this.setState({
                switchedOn: routeType === "POSTAL" ? false : true,
                isLoading: false
            })
        })
        .catch(error => {
            this.props.showMessage(true, "Server Error! ");
        });
    }
    generateToken = (e) => {
        this.props.showCustomMessage(true, "Please wait ...");
        axios.post('https://gpwapana.prithibiconsulting.com:8010/api/auth/login?token=',
            {
                accountId: this.props.accId,
                division: 'GPA'
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer'
                },
            })
            .then(response => {
                this.props.hideCustomMessage(true, "");
                const result = response.data.result;
                const routeType = result.details.billRouteType
                this.setState({
                    switchedOn: routeType === "POSTAL" ? false : true,
                    isLoading: false
                })
            })
            .catch(error => {
                this.props.hideCustomMessage(true, "");
                this.props.showMessage(true, "Server Error! ");
            });
    }
    render() { 
        return (  
            <React.Fragment>
                <p style={{margin: "0"}} onClick={this.generateToken.bind(this)}><img src={process.env.PUBLIC_URL + '/images/gear.png'} width="15px"/> Notification Settings</p>
            </React.Fragment>
        );
    }
}
 
const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, 
{ 
    showMessage, showCustomMessage, hideCustomMessage 
})(ModalNotificationSettings);